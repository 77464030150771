import React, { Component } from "react";

class ContactTwo extends Component{
    constructor(props){
        super(props);
        this.state = {
            rnName: '',
            rnEmail: '',
            rnSubject: '',
            rnMessage: '',
        };
    }
    render(){
        return(
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <h2 className="title">Contact</h2>
                                <p className="description">Want a group class in your area? Looking for private coaching? Want me to paint something for you? Use the form below to drop me a line!</p>
                            </div>
                            <div className="form-wrapper">
                                <form name="Contact" action="/success" method="POST" data-netlify="true">
                                    <input type="hidden" name="form-name" value="Contact" />
                                    <input
                                        type="text"
                                        name="name"
                                        placeholder="Your Name *"
                                    />
                                    <input
                                        type="text"
                                        name="email"
                                        placeholder="Your email *"
                                    />
                                    <input
                                        type="text"
                                        name="subject"
                                        placeholder="Write a Subject"
                                    />
                                    <textarea
                                        type="text"
                                        name="message"
                                        placeholder="Your Message"
                                    />
                                    <button className="rn-button-style--2 btn-solid" type="submit">Submit</button>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                {/* <img src="/assets/images/about/about-6.jpg" alt="trydo"/> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactTwo;